import React from "react";

import LayoutBlog from "../components/LayoutBlog";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LogoFull from "../components/LogoFull";
import EmailForm from "../components/EmailForm";
import EmailFormInPage from "../components/EmailFormInPage";
import BackToHomeArrow from "../components/BackToHomeArrow";
import ShareLinks from "../components/ShareLinks";
import PopUpOnExitEmailForm from '../components/PopUpOnExitEmailForm';

const BlogMain = () => (
  <LayoutBlog
    title={"Crafted #002 -  Mike Sumaya, Incinerati Brewing"}
    blogUrl={"https://joincrafted.com/002-incinerati-brewing"}
    description={
      "From homebrewer to brewing company owner, Mike Sumaya - Head Brewer & Owner at Incinerati Brewing - gives us a glimpse into his work."
    }
    imageUrl={
      "https://www.joincrafted.com/assets/images/inciernati_brewing_instagram.jpg"
    }>
    <Header />
    <div>
      <main className="blog-main" role="main">
        <div className="blog-left">
          <ShareLinks
            sharedLink={"mylink"}
            twitterLink={
              "https://twitter.com/intent/tweet?text=From%20homebrewer%20to%20brewing%20company%20owner,%20Mike%20Sumaya%20-%20Head%20Brewer%20%26%20Owner%20at%20Incinerati%20Brewing%20-%20gives%20us%20a%20glimpse%20into%20his%20work.%0A%0ACheck%20it%20out%20at%20https%3A//joincrafted.com/002-incinerati-brewing"
            }
            facebookLink={
              "https://www.facebook.com/sharer/sharer.php?u=https%3A//joincrafted.com/002-incinerati-brewing"
            }
          />
        </div>
        <div className="slim-container">
          <div className="mt-4 mx-2 d-flex justify-content-between">
            <div>
              <BackToHomeArrow />
            </div>
            <div
              style={{
                color: "rgba(40, 50, 78, 0.5)",
                fontSize: "16px",
                lineHeight: "1.5",
              }}
            >
              <p>September 14, 2020</p>
            </div>
          </div>
          <div className="100vw d-flex justify-content-center mt-4">
            <LogoFull></LogoFull>
          </div>
          <table className="document" align="center">
            <tbody>
              <tr>
                <td valign="top">
                  {/* Main */}
                  <table
                    cellSpacing={0}
                    cellPadding={0}
                    border={0}
                    align="center"
                    width={630}
                    className="container"
                  >
                    <tbody>
                      <tr className="inner-body">
                        <td>
                          <table
                            cellSpacing={0}
                            cellPadding={0}
                            border={0}
                            align="center"
                            width="100%"
                          >
                            {/* Header!*/}
                            <tbody>
                              {/*Main body*/}
                              <tr style={{ backgroundColor: "#ffffff" }}>
                                <td className="container">
                                  <span
                                    className="fr-unprocessed"
                                    style={{ color: "rgb(40, 50, 78)" }}
                                  ></span>
                                  <span
                                    className="fr-unprocessed"
                                    style={{ color: "rgb(40, 50, 78)" }}
                                  ></span>
                                  <span
                                    className="fr-unprocessed"
                                    style={{ color: "rgb(40, 50, 78)" }}
                                  ></span>
                                  <table
                                    cellSpacing={0}
                                    cellPadding={0}
                                    border={0}
                                    align="center"
                                    width="100%"
                                  >
                                    <tbody>
                                      <tr>
                                        <td style={{ padding: "5px 5px" }}>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div style={{textAlign: 'center', marginBottom: '2em', color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                    <h1 style={{color: "rgba(40, 50, 78, 0.5)", fontWeight: '400', fontSize: '16px', lineHeight: '1.5'}}><span style={{display: 'none'}}>Crafted </span>Edition &#35;002 🍻 - Mike Sumaya, Incinerati Brewing</h1>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#28324E",
                                                      fontSize: "16px",
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <p>
                                                      Crafted exists to help us
                                                      all see beer like a
                                                      brewmaster, to appreciate
                                                      more deeply both the{" "}
                                                      <em>art and science</em>{" "}
                                                      that brewing is.
                                                    </p>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#3D405B",
                                                      fontSize: "16px",
                                                      lineHeight: "1.5",
                                                      margin: "15px 0",
                                                      backgroundColor:
                                                        "#F3F3F7",
                                                      padding: "8px",
                                                      borderRadius: "4px",
                                                    }}
                                                  >
                                                    This week I had the pleasure
                                                    of learning from{" "}
                                                    <strong>
                                                      Mike Sumaya, Head Brewer
                                                      &amp; Owner
                                                    </strong>{" "}
                                                    at{" "}
                                                    <a
                                                      href="https://untappd.com/IncineratiBrewingCo"
                                                      style={{
                                                        color: "#6821AB",
                                                        textDecoration:
                                                          "underline",
                                                      }}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                    >
                                                      <strong>
                                                        Incinerati Brewing
                                                      </strong>
                                                    </a>
                                                    —a nano brewery with roots
                                                    in Fresno, CA. He'll soon be
                                                    moving the operation up to
                                                    Clovis, CA, where he'll have
                                                    his own brewing location and
                                                    tasting room.
                                                    <br />
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#28324E",
                                                      fontSize: "16px",
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <p>
                                                      If you're in a hurry,
                                                      check out some of the
                                                      highlights:
                                                    </p>
                                                    <ul>
                                                      <li>
                                                        <em>
                                                          You really can go from
                                                          brewing in your garage
                                                          to running your own
                                                          brewing company.
                                                        </em>{" "}
                                                        <strong>
                                                          Mike started out
                                                          brewing at home
                                                        </strong>
                                                        , honing his skills
                                                        while working various
                                                        trade jobs. He then
                                                        landed a sales position
                                                        at a brewery to get his
                                                        foot in the door.{" "}
                                                        <strong>
                                                          Before long, he was
                                                          brewing professionally
                                                        </strong>
                                                        .
                                                      </li>
                                                      <li>
                                                        <strong>
                                                          Carve out your own
                                                          niche
                                                        </strong>
                                                        —be you. Its better that
                                                        way.
                                                      </li>
                                                      <li>
                                                        Skip to the bottom to
                                                        learn about a{" "}
                                                        <strong>
                                                          dip-hopped New England
                                                          IPA
                                                        </strong>
                                                        , and a slightly{" "}
                                                        <strong>
                                                          Sour Ale with peach
                                                          and apricot purée
                                                        </strong>
                                                      </li>
                                                    </ul>
                                                    <p
                                                      style={{
                                                        paddingTop: "16px",
                                                      }}
                                                    >
                                                      Let's get to it 🚀
                                                    </p>
                                                    <EmailFormInPage />
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            cellSpacing={0}
                                            cellPadding={0}
                                            border={0}
                                            align="center"
                                            width="100%"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  className="container"
                                                  style={{
                                                    paddingTop: "15px",
                                                    paddingBottom: "15px",
                                                  }}
                                                >
                                                  <span
                                                    className="fr-unprocessed"
                                                    style={{
                                                      color: "rgb(40, 50, 78)",
                                                    }}
                                                  >
                                                    {" "}
                                                  </span>
                                                  <div
                                                    className="text-block"
                                                    style={{
                                                      lineHeight: "150%",
                                                    }}
                                                  >
                                                    <span
                                                      className="fr-unprocessed"
                                                      style={{
                                                        color:
                                                          "rgb(40, 50, 78)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(40, 50, 78)",
                                                        }}
                                                      >
                                                        {" "}
                                                      </span>
                                                    </span>
                                                    <div
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      <span
                                                        className="fr-unprocessed"
                                                        style={{
                                                          color:
                                                            "rgb(40, 50, 78)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(40, 50, 78)",
                                                          }}
                                                        >
                                                          ***
                                                        </span>
                                                        <br />
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <span
                                                    className="fr-unprocessed"
                                                    style={{
                                                      color: "rgb(40, 50, 78)",
                                                    }}
                                                  >
                                                    {" "}
                                                  </span>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#DC8718",
                                                      fontSize: "16px",
                                                      lineHeight: "1.5",
                                                      margin: "15px 0",
                                                      backgroundColor:
                                                        "#FDF6ED",
                                                      padding: "8px",
                                                      borderRadius: "4px",
                                                    }}
                                                  >
                                                    <strong>
                                                      Boiling it down to a 5-7
                                                      minute read
                                                    </strong>
                                                    <br />
                                                    What follows are merely the
                                                    highlights of my
                                                    conversation with Mike.{" "}
                                                    <br />
                                                    Though I couldn't fit it,
                                                    among other things, we also
                                                    talked about Mike's{" "}
                                                    <strong>
                                                      opinion on Seltzer's
                                                    </strong>{" "}
                                                    (he understands the business
                                                    aspect but hopes his brewery
                                                    will never have to make them
                                                    🙂)
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#00778f",
                                                      fontSize: "16px",
                                                      lineHeight: "1.5",
                                                      margin: "15px 0",
                                                      backgroundColor:
                                                        "#ebf6fb",
                                                      padding: "8px",
                                                      borderRadius: "4px",
                                                    }}
                                                  >
                                                    <strong>
                                                      Frist, a snapshot of
                                                      Incinerati Brewing Co.{" "}
                                                    </strong>{" "}
                                                    <em>
                                                      {" "}
                                                      in Mike's own words:
                                                    </em>
                                                    <br />
                                                    <br />
                                                    There's a certain kind of
                                                    character of Incinerati that
                                                    I'm trying to have: able to
                                                    survive on making good
                                                    craft-quality, experimental,
                                                    pushing-the-limits kind of
                                                    beers while still being
                                                    approachable enough to not
                                                    just stay in that little
                                                    niche in the corner.
                                                    <br />
                                                    <br />
                                                    And I really do like pushing
                                                    people from not really being
                                                    into craft beer, into trying
                                                    something that otherwise
                                                    maybe they didn't like an
                                                    example of. But I make it in
                                                    a way that they can
                                                    appreciate and also people
                                                    who are more experienced can
                                                    appreciate.
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            border={0}
                                            className="container"
                                            align="center"
                                            cellPadding={0}
                                            cellSpacing={0}
                                            width="100%"
                                            style={{
                                              marginBottom: "5px",
                                              marginTop: "16px",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  align="center"
                                                  className="hero"
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderSpacing: "0px",
                                                    margin: "0px",
                                                    padding: "0px 8px",
                                                  }}
                                                  valign="top"
                                                >
                                                  <span
                                                    className="fr-unprocessed"
                                                    style={{
                                                      color: "rgb(40, 50, 78)",
                                                    }}
                                                  >
                                                    {" "}
                                                    <img
                                                      src="https://gallery.eomail4.com/5d6ee9b8-e53b-11ea-a3d0-06b4694bee2a%2F1600027984281-incinerati_beer_untappd.png"
                                                      width={620}
                                                      style={{
                                                        width: "100%",
                                                        maxWidth: "100%",
                                                        objectFit: "cover",
                                                        padding: "0px",
                                                        outline: "0px",
                                                        border: "none",
                                                        display: "block",
                                                      }}
                                                      alt="Incinerati Beer - Untappd"
                                                    />{" "}
                                                  </span>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                              marginBottom: "16px",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: "8px 0px",
                                                    borderCollapse: "collapse",
                                                    borderSpacing: "0px",
                                                    margin: "0px",
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#28324E",
                                                      fontSize: "16px",
                                                      lineHeight: "1.5",
                                                      width: "100%",
                                                      paddingBottom: "32px",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        float: "left",
                                                        width: "50%",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      #024 Though the Heavens
                                                      Fall
                                                    </div>
                                                    <div
                                                      style={{
                                                        float: "right",
                                                        width: "50%",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      #022 Properties of
                                                      Propaganda
                                                    </div>
                                                  </div>
                                                  <div
                                                    style={{
                                                      textAlign: "center",
                                                      paddingTop: "8px",
                                                      color: "#28324E",
                                                      fontSize: "14px",
                                                      lineHeight: "1.5",
                                                      opacity: "0.5",
                                                    }}
                                                  >
                                                    Image source:
                                                    https://untappd.com/IncineratiBrewingCo/photos
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            cellSpacing={0}
                                            cellPadding={0}
                                            border={0}
                                            align="center"
                                            width="100%"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  className="container"
                                                  style={{
                                                    paddingTop: "15px",
                                                    paddingBottom: "15px",
                                                  }}
                                                >
                                                  <span
                                                    className="fr-unprocessed"
                                                    style={{
                                                      color: "rgb(40, 50, 78)",
                                                    }}
                                                  >
                                                    {" "}
                                                  </span>
                                                  <div
                                                    className="text-block"
                                                    style={{
                                                      lineHeight: "150%",
                                                    }}
                                                  >
                                                    <span
                                                      className="fr-unprocessed"
                                                      style={{
                                                        color:
                                                          "rgb(40, 50, 78)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(40, 50, 78)",
                                                        }}
                                                      >
                                                        {" "}
                                                      </span>
                                                    </span>
                                                    <div
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      <span
                                                        className="fr-unprocessed"
                                                        style={{
                                                          color:
                                                            "rgb(40, 50, 78)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(40, 50, 78)",
                                                          }}
                                                        >
                                                          ***
                                                        </span>
                                                        <br />
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <span
                                                    className="fr-unprocessed"
                                                    style={{
                                                      color: "rgb(40, 50, 78)",
                                                    }}
                                                  >
                                                    {" "}
                                                  </span>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#3D405B",
                                                      fontSize: "20px",
                                                      lineHeight: "1.5",
                                                      margin: "15px 0",
                                                      backgroundColor:
                                                        "#F3F3F7",
                                                      padding: "8px",
                                                      borderRadius: "4px",
                                                    }}
                                                  >
                                                    <strong>
                                                      When did you brew your
                                                      first batch of beer?
                                                    </strong>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#28324E",
                                                      fontSize: "16px",
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      Actually, I have the date
                                                      noted somewhere ... July
                                                      27th, 2013, that's what it
                                                      was - so 7 years ago. I
                                                      was getting so into
                                                      drinking craft beer and
                                                      IPA and stuff like that -
                                                      and somebody asked me,{" "}
                                                      <em>
                                                        Why don't you just brew
                                                        your own?
                                                      </em>{" "}
                                                    </p>
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      I was like,{" "}
                                                      <em>You can do that?</em>
                                                    </p>
                                                    <p>
                                                      I thought the best you
                                                      could do was a toilet wine
                                                      kind of quality. I didn't
                                                      realize there was this
                                                      whole thing of
                                                      homebrewing. So I jumped
                                                      into it...
                                                    </p>
                                                    <p>. . . </p>
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      So at a certain point I
                                                      wanted to get into the
                                                      industry because I was
                                                      tired of just doing pest
                                                      control [and different
                                                      trade jobs] stuff. Why
                                                      not? So I actually got
                                                      hired on at{" "}
                                                      <a
                                                        href="https://untappd.com/RileysBrewingCo"
                                                        style={{
                                                          color: "#6821AB",
                                                          textDecoration:
                                                            "underline",
                                                        }}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        Riley's Brewing
                                                      </a>{" "}
                                                      (Madera, CA) doing sales,
                                                      just because I
                                                      was...applying at any kind
                                                      of brewery I could.
                                                    </p>
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      <em>
                                                        Not even a year after
                                                        starting sales, Mike was
                                                        able to start brewing
                                                        beer at Riley's after he
                                                        "bugged them enough and
                                                        brought them enough of
                                                        my homebrew samples that
                                                        I was still brewing and
                                                        being like this is the
                                                        kind of stuff that we
                                                        need to be brewing here"
                                                      </em>
                                                    </p>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#3D405B",
                                                      fontSize: "20px",
                                                      lineHeight: "1.5",
                                                      margin: "15px 0",
                                                      backgroundColor:
                                                        "#F3F3F7",
                                                      padding: "8px",
                                                      borderRadius: "4px",
                                                    }}
                                                  >
                                                    <strong>
                                                      If Incinerati becomes what
                                                      you want it to be, what
                                                      will it be?
                                                    </strong>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#28324E",
                                                      fontSize: "16px",
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      The dream—the goal for it
                                                      is to have it be
                                                      self-sufficient so I can
                                                      do it full time, and so I
                                                      don't have to work at
                                                      another brewery and then
                                                      do this stuff nights and
                                                      weekends. So I can
                                                      dedicate all my time to
                                                      it. So we're actually
                                                      making a move on that and
                                                      going to Clovis from
                                                      Fresno. There's a brewery
                                                      called Zone 9 that's been
                                                      there for four years and
                                                      they're not renewing their
                                                      lease. So they contacted
                                                      us to so if we were
                                                      interested in moving-in -
                                                      and we definitely were.
                                                    </p>
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <em>
                                                        Right now, Mike &amp;
                                                        Incinerati are{" "}
                                                        <strong>
                                                          brewing out of an
                                                          incubator
                                                        </strong>
                                                        , shared with several
                                                        other brewing companies
                                                        near downtown Fresno.
                                                        Mike hopes to be in the
                                                        new location sometime in
                                                        October. With the
                                                        challenges of COVID
                                                        restrictions, the short
                                                        term plan is to set up a
                                                        Crowler Club before
                                                        eventually being able to
                                                        open the tasting room.
                                                      </em>
                                                    </p>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#00778f",
                                                      fontSize: "16px",
                                                      lineHeight: "1.5",
                                                      margin: "15px 0",
                                                      backgroundColor:
                                                        "#ebf6fb",
                                                      padding: "8px",
                                                      borderRadius: "4px",
                                                    }}
                                                  >
                                                    <strong>
                                                      Are you wondering what an
                                                      incubator is?
                                                    </strong>
                                                    <br />
                                                    <em>
                                                      Don't worry, so was I.
                                                      Mike was able to explain
                                                      it for me:{" "}
                                                    </em>
                                                    <br />
                                                    "The official term is an{" "}
                                                    <a
                                                      href="https://www.ttb.gov/beer/brewery-alternating-proprietorships"
                                                      style={{
                                                        color: "#6821AB",
                                                        textDecoration:
                                                          "underline",
                                                      }}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                    >
                                                      alternating proprietorship
                                                    </a>
                                                    . And it basically just
                                                    means we're sharing the
                                                    brewing space, but we're all
                                                    separately licensed and
                                                    otherwise independent."
                                                    <br />
                                                    <br />
                                                    <em>
                                                      It helps save costs by
                                                      sharing space and
                                                      equipment. For Mike, it
                                                      allows him to
                                                    </em>{" "}
                                                    "keep buying all these cool
                                                    experimental hops and
                                                    spending the money on the
                                                    best ingredients rather than
                                                    trying to cut costs at any
                                                    point."
                                                  </div>
                                                  <EmailFormInPage />
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#3D405B",
                                                      fontSize: "20px",
                                                      lineHeight: "1.5",
                                                      margin: "15px 0",
                                                      backgroundColor:
                                                        "#F3F3F7",
                                                      padding: "8px",
                                                      borderRadius: "4px",
                                                    }}
                                                  >
                                                    <strong>
                                                      What's been the biggest
                                                      obstacle so far in your
                                                      journey to start your own
                                                      brewing company?
                                                    </strong>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#28324E",
                                                      fontSize: "16px",
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      The business side. You
                                                      know, I can I can make a
                                                      killer beer, but filing
                                                      [the] paperwork and
                                                      knowing how to fill it out
                                                      properly and all that, I'm
                                                      completely out of my depth
                                                      on that.
                                                    </p>
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      So [I'm] definitely having
                                                      to reach out to some
                                                      people and try to get some
                                                      help because I just didn't
                                                      really know how to do it
                                                      all. And there's not so
                                                      much that you can Google
                                                      about - how to fill-out
                                                      this particular paperwork.{" "}
                                                    </p>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#3D405B",
                                                      fontSize: "20px",
                                                      lineHeight: "1.5",
                                                      margin: "15px 0",
                                                      backgroundColor:
                                                        "#F3F3F7",
                                                      padding: "8px",
                                                      borderRadius: "4px",
                                                    }}
                                                  >
                                                    <strong>
                                                      If you want to highlight
                                                      one of your beers, which
                                                      one would it be and why?
                                                    </strong>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#28324E",
                                                      fontSize: "16px",
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      It's kind of tough because
                                                      my whole model has just
                                                      been that everything is a
                                                      one-off. And so I've
                                                      numbered each batch - so
                                                      I'm on batch 26 now.
                                                      [With] every batch there's
                                                      something different about
                                                      it. I bought a few boxes
                                                      of hops and had to use
                                                      them up. So there are
                                                      different combinations of
                                                      these. But I'm doing them
                                                      in different amounts, in
                                                      different times and in
                                                      different ways, with
                                                      different grain or
                                                      different yeast and
                                                      different add-on
                                                      ingredients ... So each
                                                      and every one of the beers
                                                      is new and
                                                      limited-release. I'm kind
                                                      of playing on that FOMO,
                                                      that "fear of missing
                                                      out".
                                                    </p>
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      Also, in craft beer people
                                                      want something new all the
                                                      time. That's it. I'm one
                                                      of those. ...You know,
                                                      I'll stand by a six pack
                                                      or four pack of something.
                                                      But I'm also getting a
                                                      couple of four packs that
                                                      are mixed of things I've
                                                      never had before.
                                                    </p>
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      So like I said before, the
                                                      best beer to look forward
                                                      to is whichever one's
                                                      coming out next because
                                                      it's going to be something
                                                      new.
                                                    </p>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#3D405B",
                                                      fontSize: "20px",
                                                      lineHeight: "1.5",
                                                      margin: "15px 0",
                                                      backgroundColor:
                                                        "#F3F3F7",
                                                      padding: "8px",
                                                      borderRadius: "4px",
                                                    }}
                                                  >
                                                    <strong>
                                                      Which one is coming out
                                                      next?
                                                    </strong>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#28324E",
                                                      fontSize: "16px",
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      The next one that's in the
                                                      tank right now and
                                                      carbonated—I'm waiting for
                                                      the label to come in
                                                      Tuesday–It's a New England
                                                      IPA. It's called "No One
                                                      Loves Me and Neither Do
                                                      I". It's the title of a{" "}
                                                      <a
                                                        href="https://www.youtube.com/watch?v=oMLHqAUyhEk&ab&ab_channel=ThemCrookedVultures-Topic"
                                                        style={{
                                                          color: "#6821AB",
                                                          textDecoration:
                                                            "underline",
                                                        }}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        <em>
                                                          Them Crooked Vulture's
                                                        </em>{" "}
                                                        song
                                                      </a>{" "}
                                                      ... This song just kicks
                                                      ass and I freakin' love it
                                                      and part of my marketing,
                                                      so-to-speak, or my model,
                                                      is doing some interesting
                                                      and off-the-wall kind of
                                                      names and not doing stuff
                                                      that's kind of been done
                                                      before. Just really
                                                      carving that niche to
                                                      stand out.
                                                    </p>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#3D405B",
                                                      fontSize: "20px",
                                                      lineHeight: "1.5",
                                                      margin: "15px 0",
                                                      backgroundColor:
                                                        "#F3F3F7",
                                                      padding: "8px",
                                                      borderRadius: "4px",
                                                    }}
                                                  >
                                                    <strong>
                                                      Is there anything
                                                      off-the-wall about this
                                                      beer?
                                                    </strong>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#28324E",
                                                      fontSize: "16px",
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      I did a hop technique that
                                                      I've just recently learned
                                                      about called dip hopping.
                                                      Normally when you're
                                                      making a [New England IPA]
                                                      at the end of the boil you
                                                      do the whirlpool and you
                                                      throw in a whole bunch of
                                                      hops. But unless you cool
                                                      that wort down to 170-180°
                                                      Fahrenheit, you're gonna
                                                      get some bitterness from
                                                      the isomerization from the
                                                      boiling temperature.{" "}
                                                    </p>
                                                    <p>
                                                      So to avoid that, while
                                                      still getting it hot
                                                      enough to pasteurize it,
                                                      hot enough to extract the
                                                      oils efficiently, I put
                                                      the hops into the the
                                                      fermenter that was already
                                                      sanitized and added
                                                      170-175° water and just
                                                      let that steep for half an
                                                      hour while I finished the
                                                      last half an hour of the
                                                      boil. And then I knocked
                                                      the wort out right into
                                                      the tank. So the goal of
                                                      that is to limit the
                                                      amount of bitterness that
                                                      we get from the isomerized
                                                      hops in the kettle.{" "}
                                                    </p>
                                                    <EmailFormInPage />
                                                    <p>. . .</p>
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      It's got Sabro®, Sultana
                                                      and Citra® hops in there.
                                                      That's where its kind of a
                                                      play on the name where
                                                      Sabro [for] some people is
                                                      is kind of polarizing:
                                                      some people really like
                                                      it, some people really
                                                      don't. Definitely has a
                                                      distinctive, strong
                                                      character to it, but that
                                                      is kind of why I like it.
                                                      But I did mix in that
                                                      Sultana for some of that
                                                      pineapple that [it]
                                                      provides and then some
                                                      Citra just to kind of back
                                                      it up.{" "}
                                                    </p>
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      So it's not way
                                                      off-the-wall. It's kind of
                                                      in the line of these New
                                                      Englands that I'm making
                                                      where every time I'm
                                                      changing some stuff up. I
                                                      have a general structure
                                                      of the recipe. But, you
                                                      know, I'll change some
                                                      things for for one reason
                                                      or another, mostly with
                                                      the hops that I'm using
                                                      and the ways and the
                                                      amounts, again, to not
                                                      make them taste the same.
                                                      But still, each one of the
                                                      batches I make, I learn
                                                      something new, something
                                                      better, how to do the
                                                      process.
                                                    </p>
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      <strong>
                                                        And then the other one
                                                        that is off-the-wall
                                                      </strong>
                                                      , that's in my five barrel
                                                      - it's a double batch -
                                                      that's actually a{" "}
                                                      <strong>
                                                        sour ale that has peach
                                                        and apricot puree
                                                      </strong>{" "}
                                                      added to it. And rather
                                                      than doing a kettle sour
                                                      where I sour it with
                                                      bacteria in the kettle or
                                                      just doing a quick sour
                                                      where you just add
                                                      straight lactic acid,
                                                      there's this new yeast
                                                      called <a
                                                      href="https://www.lallemandbrewing.com/en/united-states/product-details/wildbrew-philly-sour/"
                                                      style={{
                                                        color: "#6821AB",
                                                        textDecoration:
                                                          "underline",
                                                      }}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                    >Philly sour</a> from
                                                      Lallemand, the dry yeast
                                                      company that was
                                                      discovered by the
                                                      University of the Sciences
                                                      in Philadelphia.
                                                    </p>
                                                    <p>
                                                      And they found on a
                                                      dogwood tree in a cemetery
                                                      just outside of the
                                                      school, this particular
                                                      Lachancea species yeast.
                                                      So it's not a
                                                      Saccharomyces yeast, it is
                                                      a wild yeast, but it
                                                      happens to do lactic acid
                                                      fermentation and then
                                                      switches to ethanol
                                                      fermentation. So a little
                                                      sour and ferment in the
                                                      same tank. It's a not a
                                                      bacteria. So it's doing
                                                      just regular sanitizing.
                                                    </p>
                                                    <p>. . .</p>
                                                    <p></p>
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      I then added some Oregon
                                                      fruit, apricot and peach
                                                      purée to it so it'd be
                                                      nice and peachy. It's got
                                                      a nice sourness. It's not
                                                      a super-duper sour one,
                                                      but it definitely gives
                                                      you that pinch in the back
                                                      of your cheeks when you're
                                                      drinking it. It's nice,
                                                      clean - it's just wheat
                                                      and pilsner, a very
                                                      simple, basic kind of
                                                      grain build just to let
                                                      that yeast and the fruit
                                                      stand out and the sourness
                                                      stand out on it.
                                                    </p>
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      So that one is called
                                                      "2021: 2020 Part 2". And
                                                      it actually has pretty
                                                      funny label on there. My
                                                      cousin does my labels and
                                                      my graphic design stuff.
                                                      He had just come up with
                                                      this particular label and
                                                      I came up with the name
                                                      and it's perfect for
                                                      everything that we're all
                                                      going through - just
                                                      laughing at how fucked up
                                                      2020 is and how its been.
                                                    </p>
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      It's a little clunky but
                                                      you basically got to see
                                                      the label to get it. ... I
                                                      like to do things that are
                                                      a bit different, that
                                                      stand out on the shelf and
                                                      hopefully get some social
                                                      media love on it because I
                                                      think its a pretty awesome
                                                      label...
                                                    </p>
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      It's kind of been the goal
                                                      for Incinerati from the
                                                      start is to kind of go, in
                                                      a way, on the next level
                                                      of brewing where it's not
                                                      just, you know, "Clovis
                                                      Brown" and "San Joaquin
                                                      River Pale Ale" and stuff.
                                                      Take a little bit more
                                                      artistic and creative, a
                                                      little bit esoteric as a
                                                      character to it.
                                                    </p>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            cellSpacing={0}
                                            cellPadding={0}
                                            border={0}
                                            align="center"
                                            width="100%"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  className="container"
                                                  style={{
                                                    paddingTop: "15px",
                                                    paddingBottom: "15px",
                                                  }}
                                                >
                                                  <span
                                                    className="fr-unprocessed"
                                                    style={{
                                                      color: "rgb(40, 50, 78)",
                                                    }}
                                                  >
                                                    {" "}
                                                  </span>
                                                  <div
                                                    className="text-block"
                                                    style={{
                                                      lineHeight: "150%",
                                                    }}
                                                  >
                                                    <span
                                                      className="fr-unprocessed"
                                                      style={{
                                                        color:
                                                          "rgb(40, 50, 78)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(40, 50, 78)",
                                                        }}
                                                      >
                                                        {" "}
                                                      </span>
                                                    </span>
                                                    <div
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      <span
                                                        className="fr-unprocessed"
                                                        style={{
                                                          color:
                                                            "rgb(40, 50, 78)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(40, 50, 78)",
                                                          }}
                                                        >
                                                          ***
                                                        </span>
                                                        <br />
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <span
                                                    className="fr-unprocessed"
                                                    style={{
                                                      color: "rgb(40, 50, 78)",
                                                    }}
                                                  >
                                                    {" "}
                                                  </span>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#28324E",
                                                      fontSize: "16px",
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <p
                                                      style={{
                                                        paddingBottom: "8px",
                                                      }}
                                                    >
                                                      If you get to{" "}
                                                      <strong>
                                                        make a living
                                                      </strong>{" "}
                                                      and{" "}
                                                      <strong>
                                                        express youself
                                                      </strong>{" "}
                                                      by{" "}
                                                      <strong>
                                                        doing what you are
                                                        passionate about
                                                      </strong>{" "}
                                                      - count yourself blessed.
                                                    </p>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: 0,
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    margin: 0,
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#4cb14c",
                                                      fontSize: "16px",
                                                      lineHeight: "1.5",
                                                      backgroundColor:
                                                        "#F3F9F0",
                                                      padding: "8px",
                                                      borderRadius: "4px",
                                                      margin: "15px 0",
                                                    }}
                                                  >
                                                    <strong>
                                                      Thank you to Mike Sumaya
                                                    </strong>{" "}
                                                    for sharing his time in
                                                    order to give us a glimpse
                                                    into his work. There's a lot
                                                    to learn from your
                                                    experiences. Crafted wishes
                                                    you the best of luck moving
                                                    out to the new location, and
                                                    taking the next step on your
                                                    journey towards brewing for
                                                    yourself full-time at
                                                    Incinerati.
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table
                                            width="100%"
                                            style={{
                                              tableLayout: "fixed",
                                              width: "100%",
                                              marginBottom: "16px",
                                            }}>
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: "0px 0px 8px",
                                                    borderCollapse: "collapse",
                                                    borderSpacing: "0px",
                                                    margin: "0px",
                                                  }}
                                                  valign="top"
                                                >
                                                  <div
                                                    style={{
                                                      color: "#28324E",
                                                      fontSize: "16px",
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                        paddingTop: "16px"
                                                      }}
                                                    >
                                                      Also{" "}
                                                      <strong>thank you</strong>{" "}
                                                      to you who have read this
                                                      far - Crafted would be
                                                      meaningless without you!
                                                    </p>
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      As always, feel free to
                                                      let me know what you
                                                      think, and tell me what
                                                      you're drinking.
                                                    </p>
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      Take care, until next time
                                                      👋
                                                    </p>
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      Cheers! 🍻
                                                    </p>
                                                    <p
                                                      style={{
                                                        paddingBottom: "16px",
                                                      }}
                                                    >
                                                      - Jake
                                                    </p>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <span
                                    className="fr-unprocessed"
                                    style={{ color: "rgb(40, 50, 78)" }}
                                  ></span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          <div className="spacer-div"></div>
        </div>
        <div className="blog-right"></div>
      </main>
    </div>
    <div id="overlay">
      <div id="loader-object"></div>
    </div>
    <Footer />
  </LayoutBlog>
);

export default BlogMain;
